import React, { useState } from "react"
import { Link } from "gatsby"

import LinkedInIcon from "@material-ui/icons/LinkedIn"
import FacebookIcon from "@material-ui/icons/Facebook"
import InstagramIcon from "@material-ui/icons/Instagram"

import MenuItem from "./MenuItem"
import PhoneMenuItem from "./PhoneMenuItem"

export default function Menu({ menuOpen, toggleMenu }) {
  const [submenuOpen, setSubmenuOpen] = useState(false)

  const whatWeDoItems = {
    name: "what we do",
    submenuItems: ["why use builder finders", "compare the builders", "faq"],
  }
  const aboutUsItems = {
    name: "about us",
    submenuItems: ["meet the team", "testimonials"],
  }
  const findABuilderItems = {
    name: "find a builder",
    submenuItems: ["new build", "renovation", "contact us"],
  }
  // const findABuilderItems = {
  //   name: "find a builder",
  //   submenuItems: ["contact us"],
  // }

  return (
    <div
      className={
        "fixed inset-0 z-50 bg-primary flex flex-col justify-center items-center duration-700 transform " +
        (menuOpen
          ? "pointer-events-auto opacity-100 translate-y-0"
          : "pointer-events-none opacity-0 -translate-y-full")
      }
    >
      <div
        className={
          "duration-300 w-full lg:w-auto px-8 lg:px-16 " +
          (menuOpen ? "delay-700 opacity-100" : "opacity-0")
        }
      >
        <button
          className="absolute top-0 right-0 py-4 px-8 navigation-text-style hover:text-white"
          onClick={toggleMenu}
        >
          close
        </button>
        <div className="text-white flex flex-col space-y-20 sm:space-y-6 md:space-y-20">
          <div className="overflow-hidden lg:overflow-auto w-full sm:w-1/2 lg:w-full">
            <ul
              className={
                "lg:hidden relative space-y-4 opacity-100 duration-300 transform " +
                (submenuOpen ? "-translate-x-full" : "")
              }
            >
              <li>
                <MenuLink to="/" toggleMenu={toggleMenu}>
                  home
                </MenuLink>
              </li>
              <li>
                <PhoneMenuItem
                  items={whatWeDoItems}
                  submenuOpen={submenuOpen}
                  setSubmenuOpen={setSubmenuOpen}
                  toggleMenu={toggleMenu}
                />
              </li>
              <li>
                <PhoneMenuItem
                  items={aboutUsItems}
                  submenuOpen={submenuOpen}
                  setSubmenuOpen={setSubmenuOpen}
                  toggleMenu={toggleMenu}
                />
              </li>
              <li>
                <PhoneMenuItem
                  items={findABuilderItems}
                  submenuOpen={submenuOpen}
                  setSubmenuOpen={setSubmenuOpen}
                  toggleMenu={toggleMenu}
                />
              </li>
              <li className="flex flex-col space-y-4">
                <MenuLink to="/media/blog" toggleMenu={toggleMenu}>
                  blog
                </MenuLink>
                <MenuLink to="/media/press" toggleMenu={toggleMenu}>
                  press
                </MenuLink>
              </li>
            </ul>
            <ul className="hidden lg:flex relative space-x-[8vw] flex-row">
              <li>
                <MenuLink to="/" toggleMenu={toggleMenu}>
                  home
                </MenuLink>
              </li>
              <li>
                <MenuItem
                  items={whatWeDoItems}
                  submenuOpen={submenuOpen}
                  setSubmenuOpen={setSubmenuOpen}
                  toggleMenu={toggleMenu}
                />
              </li>
              <li>
                <MenuItem
                  items={aboutUsItems}
                  submenuOpen={submenuOpen}
                  setSubmenuOpen={setSubmenuOpen}
                  toggleMenu={toggleMenu}
                />
              </li>
              <li>
                <MenuItem
                  items={findABuilderItems}
                  submenuOpen={submenuOpen}
                  setSubmenuOpen={setSubmenuOpen}
                  toggleMenu={toggleMenu}
                />
              </li>
              <li className="flex flex-col space-y-4">
                <MenuLink to="/media/blog" toggleMenu={toggleMenu}>
                  blog
                </MenuLink>
                <MenuLink to="/media/press" toggleMenu={toggleMenu}>
                  press
                </MenuLink>
              </li>
            </ul>
          </div>
          <div className="-ml-1.5 flex flex-row space-x-4 text-6xl">
            <a
              href="https://www.linkedin.com/company/builderfinders"
              target="_blank"
              rel="noreferrer"
              className="text-blue-300 hover:text-white duration-300"
            >
              <LinkedInIcon color="inherit" fontSize="inherit" />
            </a>
            <a
              href="https://www.facebook.com/builderfinders"
              target="_blank"
              rel="noreferrer"
              className="text-blue-300 hover:text-white duration-300"
            >
              <FacebookIcon color="inherit" fontSize="inherit" />
            </a>
            <a
              href="https://www.instagram.com/builderfinders/"
              target="_blank"
              rel="noreferrer"
              className="text-blue-300 hover:text-white duration-300"
            >
              <InstagramIcon color="inherit" fontSize="inherit" />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

function MenuLink({ to, children, toggleMenu }) {
  return (
    <Link
      to={to}
      activeClassName="!text-white"
      className="menu-font"
      onClick={toggleMenu}
    >
      {children}
    </Link>
  )
}
