import React, { useState } from "react"
import Submenu from "./Submenu"

export default function PhoneMenuItem({
  setSubmenuOpen,
  submenuOpen,
  items,
  toggleMenu,
  ...props
}) {
  const [open, setOpen] = useState(false)

  function handleToggle() {
    setOpen(!open)
    setSubmenuOpen(!submenuOpen)
  }

  const mainItem = { name: items.name, to: "/" + slugify(items.name) + "/" }
  const submenuItems = items.submenuItems.map(item => {
    return { name: item, to: mainItem.to + slugify(item) }
  })
  const menuItems = [mainItem, ...submenuItems]

  return (
    <>
      <button
        onClick={handleToggle}
        className={"max-w-md menu-font " + (open ? "text-white" : "")}
      >
        {items.name}
      </button>
      {open ? (
        <div
          className={
            "absolute top-0 left-full duration-300 w-96 " +
            (open ? "opacity-100" : "pointer-events-none opacity-0")
          }
        >
          <Submenu
            items={menuItems}
            handleToggle={handleToggle}
            toggleMenu={toggleMenu}
          />
        </div>
      ) : null}
    </>
  )
}

function slugify(str) {
  str = str.replace(/^\s+|\s+$/g, "")
  str = str.toLowerCase()

  const from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;"
  const to = "aaaaeeeeiiiioooouuuunc------"
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i))
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-")

  return str
}
