import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

export default function Footer() {
  return (
    <footer className="flex flex-col p-4 mt-auto">
      <div className="wrapper bg-primary p-4">
        <div className="flex flex-col lg:flex-row justify-between space-y-4 sm:space-y-6 lg:space-y-0 lg:space-x-12">
          <div className="flex flex-col">
            <Link className="w-36 mb-8" to="/">
              <StaticImage
                src="../images/logo-white.png"
                objectFit="contain"
                alt="Builder Finders Logo"
              />
            </Link>
            <div className="text-white">
              <p className="thin-subtitle mb-2">contact us</p>
              <div className="grid grid-cols-footer gap-2 text-xs contact-footer">
                <p>Email:</p>
                <p>admin@builderfinders.com.au</p>
                <p>Phone:</p>
                <div className="flex flex-col">
                  <p>1300 4 A BUILDER</p>
                  <p>1300 422 845</p>
                </div>
                <p>Post:</p>
                <p>PO Box 4715 Sylvania Waters NSW 2224</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row text-white text-xs space-y-6 sm:space-y-0 sm:space-x-12 lg:space-x-20">
            <div className="footer-link-group">
              <Link to="/what-we-do/" className="title">
                What We Do
              </Link>
              <Link to="/what-we-do/why-use-builder-finders">
                Why use Builder Finders
              </Link>
              <Link to="/what-we-do/compare-the-builders">
                Compare the Builders
              </Link>
              <Link to="/what-we-do/faq">FAQ</Link>
            </div>
            <div className="footer-link-group">
              <Link to="/about-us/" className="title">
                About Us
              </Link>
              <Link to="/about-us/meet-the-team">Meet the Team</Link>
              <Link to="/about-us/testimonials">Testimonials</Link>
            </div>
            <div className="footer-link-group">
              <Link to="/find-a-builder/" className="title">
                Find a Builder
              </Link>
              <Link to="/find-a-builder/new-build">New Build</Link>
              {/*<Link to="/find-a-builder/contact-us">New Build</Link>*/}
              <Link to="/find-a-builder/renovation">Renovate</Link>
              {/*<Link to="/find-a-builder/contact-us">Renovate</Link>*/}
              <Link to="/find-a-builder/contact-us">Contact Us</Link>
            </div>
            <div className="footer-link-group">
              <Link to="/media/press">Press</Link>
              <Link to="/media/blog">Blog</Link>
            </div>
            <div className="footer-link-group">
              <Link to="/partners">Partners</Link>
              <Link to="/transparency">Transparency Policy</Link>
              <Link to="/privacy">Privacy Policy</Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
