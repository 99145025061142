import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

export default function Navigation({ toggleMenu }) {
  return (
    <nav className="sticky top-0 h-[6vh] sm:h-[10vh] md:h-5vh bg-white bg-opacity-75 z-40 backdrop-filter  backdrop-blur">
      <div className="absolute inset-0 px-4 pointer-events-none">
        <div className="h-full w-full border-b border-gray-200" />
      </div>
      <div className="wrapper py-0 px-4">
        <div className=" py-1.5 sm:px-4">
          <div className="flex flex-row justify-between items-center">
            <Link className="w-32" to="/">
              <StaticImage
                src="../../images/logo.png"
                objectFit="contain"
                alt="Builder Finders Logo"
              />
            </Link>
            <ul className="flex flex-row space-x-4 text-sm sm:text-base">
              <NavLink to="/find-a-builder">get started</NavLink>
              <li className="flex flex-row justify-center">
                <button
                  className="py-1 font-title text-gray-400 hover:text-black duration-300"
                  onClick={toggleMenu}
                >
                  menu
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  )
}

function NavLink({ children, to }) {
  return (
    <li className="flex flex-row justify-center">
      <Link
        to={to}
        activeClassName="!text-black"
        className="py-1 font-title text-gray-400 hover:text-black duration-300"
      >
        {children}
      </Link>
    </li>
  )
}
