import React from "react"
import { Link } from "gatsby"

export default function MenuItem({ items, toggleMenu }) {
  // const [open, setOpen] = useState(false)
  // const [canClose, setCanClose] = useState(false)
  // const toggleSubmenu = () => {
  //   setSubmenuOpen(!submenuOpen)
  // }
  // const toggle = () => {
  //   setOpen(!open)
  // }
  // function handleToggle() {
  //   toggle()
  //   toggleSubmenu()
  // }

  // const menuItem = {name: name, to: to}
  // submenuItems.forEach((item) => item.to = to + item.to)
  // submenuItems.unshift(menuItem)

  const mainItem = { name: items.name, to: "/" + slugify(items.name) + "/" }
  const submenuItems = items.submenuItems.map(item => {
    return { name: item, to: mainItem.to + slugify(item) }
  })
  const menuItems = [mainItem, ...submenuItems]

  return (
    // <div className="flex flex-row">
    //   <button
    //     onClick={handleToggle}
    //     className={
    //       "uppercase text-4xl font-title text-blue-300 hover:text-white duration-300 whitespace-nowrap " +
    //       (open ? "text-white" : "")
    //     }
    //   >
    //     {items.name}
    //   </button>
    //   {open ? (
    //     <div
    //       className={
    //         "absolute top-0 left-full duration-300 " +
    //         (open ? "opacity-100" : "pointer-events-none opacity-0")
    //       }
    //     >
    //       <Submenu items={menuItems} handleToggle={handleToggle} />
    //     </div>
    //   ) : null}
    // </div>
    <ul className="flex flex-col space-y-4 max-w-[14rem]">
      {menuItems.map(item => (
        <li className="navlink flex">
          <Link
            to={item.to}
            className="max-w-xs menu-font"
            activeClassName="!text-white"
            onClick={toggleMenu}
          >
            {item.name}
          </Link>
        </li>
      ))}
    </ul>
  )
}

function slugify(str) {
  str = str.replace(/^\s+|\s+$/g, "")
  str = str.toLowerCase()

  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;"
  var to = "aaaaeeeeiiiioooouuuunc------"
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i))
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-")

  return str
}