import React, { useState } from "react"
import Footer from "./Footer"
import InConstruction from "./InConstruction"
import Navigation from "./navigation/Navigation"
import Menu from "./navigation/Menu"
import useBodyLock from "./useBodyLock"

export default function Layout({ children, heroBeforeNav }) {
  const [menuOpen, setMenuOpen] = useState(false)
  const [locked, setLocked] = useBodyLock()
  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
    setLocked(!locked)
  }

  return (
    <main
      class={
        "flex flex-col min-h-screen " + (menuOpen ? "overflow-hidden" : "")
      }
    >
      <Menu menuOpen={menuOpen} toggleMenu={toggleMenu} />
      {heroBeforeNav}
      <div>
        <Navigation toggleMenu={toggleMenu} />
        {children ? children : <InConstruction />}
      </div>
      <Footer />
    </main>
  )
}
