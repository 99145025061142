import React from "react"
import { Link } from "gatsby"

export default function Submenu({ items, handleToggle, toggleMenu }) {
  return (
    <div>
      <ul className="space-y-2 flex flex-col">
        <li>
          <button className="menu-font" onClick={handleToggle}>
            Go Back
          </button>
        </li>
        {items.map(item => (
          <li className="navlink w-60">
            <Link
              to={item.to}
              className="menu-font"
              activeClassName="!text-white"
              onClick={toggleMenu}
            >
              {item.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}
