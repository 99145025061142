import { navigate } from "gatsby"
import React from "react"

export default function InConstruction() {
  return (
    <section className="absolute inset-0 flex flex-col justify-center items-center">
      <div className="flex flex-col max-w-xs items-center">
        <p className="font-title text-3xl text-center mb-8">
          Sorry, this page is still in construction.
        </p>
        <button
          className="bg-primary text-white py-2 px-6 text-sm"
          onClick={() => navigate(-1)}
        >
          Go Back
        </button>
      </div>
    </section>
  )
}
